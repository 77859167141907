import React, { useState, useRef, useEffect, useLayoutEffect, ReactNode } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Layout from '~/components/Layout';
import useCategories from '~/hooks/useCategories';
import { Icon, IconType } from '~/components/elements/Icon';

import FlexibleContent from '~/components/FlexibleContent';
import Resources from '~/components/flexible/Resources';
import FeaturedResources from '~/components/flexible/FeaturedResources';
import Newsletter from '~/components/flexible/Newsletter';
import useBlogPosts from '~/hooks/useBlogPosts';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { Link, graphql } from 'gatsby';

export default function resourcesPage({ data: { wpPage, wp } }) {
  const { nodes } = useCategories();

  const [query, setQuery] = React.useState('');

  const { allWpPost } = useBlogPosts();

  return (
    <Layout wp={wp} resourcesHeader activeSearchQuery={query} setQuery={setQuery}>
      <Seo post={wpPage} />
      <FlexibleContent content={wpPage?.flexibleContent?.flexibleContent} />
      <Filter
        categories={wpPage?.page?.page?.resources?.resourcesFilter?.filterCategories}
        tags={wpPage?.page?.page?.resources?.resourcesFilter?.filterTags}
      />
      <FeaturedResources posts={allWpPost?.nodes} />

      {wpPage?.page?.page?.resources?.resourceCategories?.map(
        (category, i) =>
          category?.category?.posts?.nodes?.length > 0 && <Resources {...category} key={`resourcesPageResource${i}`} />
      )}
      <Newsletter {...wpPage?.page?.page?.newsletter} />
    </Layout>
  );
}

export const Filter = ({ categories, tags }) => {
  return (
    <div className="container flex flex-col md:flex-row justify-center space-y-6 md:space-y-0 md:space-x-6 mb-10 md:mb-[90px]">
      <FilterButton items={categories} title="Categories" />
      <FilterButton items={tags} title="Tags" tags />
      {/* <FilterButton items={tags} /> */}
    </div>
  );
};

export const FilterButton = ({ title, items, tags = false }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="relative min-w-[283px]">
      <button
        className=" w-full border border-[#CFCFCF] rounded-[3px] flex items-center px-3 py-2"
        onClick={() => setExpanded(!!!expanded)}
      >
        <div className="opacity-30">{title}</div>
        <div className="flex items-center justify-center w-6 h-6 ml-auto transition-colors duration-300 ease-in-out rounded-full bg-beige text-navy group-hover:bg-navy">
          <div
            className={`w-[10px] fw-icon transition-transform duration-300 ease-in-out transform ${expanded ? 'rotate-0' : 'rotate-180'
              }`}
          >
            <Icon type={IconType.ChevronUp} />
          </div>
        </div>
      </button>
      <AnimatePresence>
        {expanded && (
          <motion.ul
            className="bg-white text-black filter-button-shadow px-3 py-1 rounded-[5px] absolute top-[calc(100%+12px)] z-20 left-[3px] right-0 overflow-hidden"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.3 }}
          >
            {items?.map((item, i) => (
              <li key={`filterButton${i}`} className={``}>
                <Link
                  to={tags ? item?.tag?.uri : item?.category?.uri}
                  className={`px-3 py-2 flex items-center hover:bg-beige transition-colors duration-200 ease-in-out group rounded-[3px]`}
                  activeClassName="hidden"
                >
                  <span className="capitalize transition-opacity duration-200 ease-in-out opacity-40 group-hover:opacity-100">
                    {tags ? item?.tag?.name : item?.category?.name}
                  </span>
                </Link>
              </li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
};

export const pageQuery = graphql`
  query Resources($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
  }
`;
